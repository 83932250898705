import React, {
  KeyboardEvent,
  useState,
  useRef,
  MutableRefObject,
  useEffect,
  useCallback,
} from "react";
import ModReceiptCode from "./ModReceiptCode";
// Config
import CONFIG from "config/config";
import { useIntl } from "react-intl";

// Types
type CardReceiptNumberProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;

  // CommonInterface
  errorMessage?: Record<string, any>;
  successMessage?: Record<string, any>;
  // data
  bilReceiptCodeDetail?: {
    code: string;
    BIL_RECEIPT_SYSTEM_PREFIX: string;
    BIL_RECEIPT_PREFIX_SIZE: number;
    BIL_RECEIPT_RUNNING_SIZE: number;
  };
};

const CardReceiptNumber = (props: CardReceiptNumberProps) => {
  const intl = useIntl();
  return (
    <div style={{ padding: "10px" }}>
      <div>{intl.formatMessage({ id: "ตั้งเลขที่ใบเสร็จ" })}</div>

      {!props.constanceConfig.BIL_AUTO_RUN_RECEIPT && (
        <ModReceiptCode
          onEvent={props.onEvent}
          setProp={props.setProp}
          // data
          bilReceiptCodeDetail={props.bilReceiptCodeDetail}
          stationName={props.stationName}
          // CommonInterface
          successMessage={props.successMessage}
          errorMessage={props.errorMessage}
          // config
          isDefault={true}
          languageUX={props.languageUX}
        />
      )}
    </div>
  );
};

export default React.memo(CardReceiptNumber);
